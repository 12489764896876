.btn {
  border-radius: 28px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--button-big, linear-gradient(180deg, #FF6900 0%, #CC5400 100%));
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset;display: flex;
  padding: 8px 12px;
  color: #fff;
}

.box {
  //position: relative;
  //width: 50px;
  display: flex;
  justify-content: center;
  .handler {
    position: absolute;
    display: inline-flex;
    white-space: nowrap;
    padding: 4px 6px;
    border-radius: 12px;
    flex-wrap: nowrap;
    color: #FFF;
    font-family: Inter;
    font-size: 9px;
    font-weight: 700;
    text-transform: capitalize;
    bottom: -12px;
    transform: translateX(-50%);
    left: 50%;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: linear-gradient(180deg, #FFB800 0%, #D05700 100%);
  }
  .over {
    background: #A4A4A4;
    border: none;
  }
}
